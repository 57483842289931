import {
    getIsExtended,
    getIsTimedPlus,
    getItemModelIsPassed,
    getItemModelIsPaused,
    getItemModelIsSold,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { useAppSelector } from '@/redux/hooks';
import ExtendedBadge from '@liveauctioneers/caterwaul-components/lib/Badges/ExtendedBadge';
import ItemCardBadge from '@/components/GridItemCard/Card/components/ItemCardBadge/ItemCardBadge';
import styled, { css, keyframes } from 'styled-components';

export type TimedBadgeContainerProps = {
    // If on a larger item image (e.g. the main image on the item page), we have a 16px on the top and left.
    // Otherwise, we have 9px.
    isLargeImage?: boolean;
    itemId: number;
};

const TimedBadgeContainer = ({ isLargeImage = false, itemId }: TimedBadgeContainerProps) => {
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));
    const isPaused = useAppSelector((state) => getItemModelIsPaused(state, itemId));
    const isTimedPlus = useAppSelector((state) => getIsTimedPlus(state, itemId));
    const isExtended = useAppSelector((state) => getIsExtended(state, itemId));

    if (!isSold && !isPassed && !isPaused && isTimedPlus && isExtended) {
        return (
            <StyledTimedBadge
                $isLargeImage={isLargeImage}
                data-testid="timed-badge"
            >
                <StyledExtendedBadge testid="extendedBadge" />
            </StyledTimedBadge>
        );
    }

    return (
        <StyledTimedBadge
            $isLargeImage={isLargeImage}
            data-testid="timed-badge"
        >
            <ItemCardBadge itemId={itemId} />
        </StyledTimedBadge>
    );
};

export default TimedBadgeContainer;

const StyledTimedBadge = styled.div<{ $isLargeImage: boolean }>`
    position: absolute;
    // z-index needs to be this in order to show above zoomed-in image on item page
    z-index: 6;

    ${({ $isLargeImage }) => {
        const spacing = $isLargeImage ? 16 : 9;
        return css`
            left: ${spacing}px;
            top: ${spacing}px;
        `;
    }}
`;

const Pulse = keyframes`
    0% {
        opacity: 1;
    }


    50% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }

    `;

const StyledExtendedBadge = styled(ExtendedBadge)`
    animation: ${Pulse} 5s linear infinite;
`;
