import * as React from 'react';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import LiveInfoBadge from '@liveauctioneers/caterwaul-components/lib/Badges/LiveInfoBadge';

type Props = {
    className?: string;
    hasVideo?: boolean;
    myItemsSavedItemsCard?: boolean;
    pause?: boolean;
    viewers: number;
};

const ConsoleSocialBadge = ({ className = '', hasVideo, myItemsSavedItemsCard, pause, viewers = 1 }: Props) => {
    const { formatMessage } = useIntl();
    return (
        <LiveInfoBadge
            className={className}
            liveText={myItemsSavedItemsCard ? '' : formatMessage({ id: 'live' }).toUpperCase()}
            pause={pause}
            pauseText={formatMessage({ id: 'paused' }).toUpperCase()}
            testid="console-social-badge"
            video={hasVideo}
        >
            {viewers}
        </LiveInfoBadge>
    );
};

export default React.memo<Props>(ConsoleSocialBadge);
