import findIndex from 'lodash/findIndex';
import findLast from 'lodash/findLast';
import findLastIndex from 'lodash/findLastIndex';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';

export const MAX_BID_LIMIT = 9999999999.99 as const;

type BidIncrement = {
    fromAmount: number;
    incAmount: number;
};

/**
 * Generates a list of all possible bidding options given a list of bid increments and a starting bid
 */
export const getAllBidOptions = (bidIncrements: BidIncrement[], minimumBid: number): number[] => {
    if (!Array.isArray(bidIncrements) || !bidIncrements.length || !Number.isFinite(minimumBid)) {
        return [];
    }

    const startIncrement = findLast(bidIncrements, ({ fromAmount }) => fromAmount <= minimumBid);

    const bidOptions = [];
    if (!startIncrement) {
        return bidOptions;
    }

    for (let i = startIncrement.fromAmount; bidOptions.length < 600; ) {
        const bidIncrement = findLast(bidIncrements, (increment) => {
            return increment.fromAmount <= i;
        });

        if (i > MAX_BID_LIMIT) {
            break;
        }

        const indexOfIncrement = findIndex(bidIncrements, (increment) => {
            return increment.incAmount === bidIncrement.incAmount && increment.fromAmount === bidIncrement.fromAmount;
        });

        let nextIncrement;
        if (indexOfIncrement + 1 <= bidIncrements.length) {
            nextIncrement = bidIncrements[indexOfIncrement + 1];
        }

        if (nextIncrement && nextIncrement.fromAmount < i) {
            i = nextIncrement.fromAmount;
        }

        bidOptions.push(i);

        // We're in a weird place where we will pass the next increment incorrectly so let's try not to
        if (nextIncrement && i + bidIncrement.incAmount > nextIncrement.fromAmount) {
            i = nextIncrement.fromAmount;
            continue;
        }

        i += bidIncrement.incAmount;
    }

    return bidOptions;
};

/**
 * Generates a list of bidding options given a list of bid increments and a starting bid
 */
export const generateBidOptions = (bidIncrements: BidIncrement[], bidAmount: number, minimumBid: number): number[] => {
    if (
        !Array.isArray(bidIncrements) ||
        !bidIncrements.length ||
        !Number.isFinite(bidAmount) ||
        !Number.isFinite(minimumBid)
    ) {
        return [];
    }

    // Get list of all possible bidding options
    const allBidOptions = getAllBidOptions(bidIncrements, minimumBid);

    const minimumBidIndex = findLastIndex(allBidOptions, (i) => i === minimumBid);
    const newBidIndex = findLastIndex(allBidOptions, (i) => i <= bidAmount);

    // Insert custom bid into list
    const MAX_OPTIONS_LENGTH = 50;
    const underOptions = takeRight(allBidOptions.slice(minimumBidIndex, newBidIndex + 1), 25);
    const overOptions = take(allBidOptions.slice(newBidIndex + 1), MAX_OPTIONS_LENGTH - underOptions.length);
    const newBidOption = allBidOptions[newBidIndex] !== bidAmount ? [bidAmount] : [];

    // Return the custom bid with
    return [...underOptions, ...newBidOption, ...overOptions];
};

/**
 * Rounds down a given absentee bid amount to the nearest bid increment
 */
export const roundAbsenteeBid = (bidIncrements: BidIncrement[], bidAmount: number): number => {
    if (!Array.isArray(bidIncrements) || !bidIncrements.length || !Number.isFinite(bidAmount)) {
        return null;
    }

    const { fromAmount, incAmount } = findLast(bidIncrements, ({ fromAmount }) => fromAmount <= bidAmount);

    // bid amount doesn't need rounding
    if ((bidAmount - fromAmount) % incAmount === 0) {
        return bidAmount;
    }

    // round down bid amount to nearest increment
    let roundedBid = fromAmount;
    while (roundedBid + incAmount < bidAmount && roundedBid + incAmount < MAX_BID_LIMIT) {
        roundedBid += incAmount;
    }

    return roundedBid;
};

/**
 * Increments a given absentee bid amount to the next bid increment
 */
export const incrementAbsenteeBid = (bidIncrements: BidIncrement[], bidAmount: number): number => {
    if (!Array.isArray(bidIncrements) || !bidIncrements.length || !Number.isFinite(bidAmount)) {
        return null;
    }

    const { incAmount } = findLast(bidIncrements, ({ fromAmount }) => fromAmount <= bidAmount);

    return bidAmount + incAmount;
};

/**
 * Returns the next valid bid minimum, given the current highest bid and start price
 */
export const getNextBidMinimum = (bidIncrements: BidIncrement[], highestBid: number, startPrice: number): number => {
    if (!Array.isArray(bidIncrements) || !bidIncrements.length || !Number.isFinite(startPrice)) {
        return null;
    }

    if (!highestBid) {
        return startPrice;
    }

    const allBidOptions = getAllBidOptions(bidIncrements, highestBid);
    const minimumBidIndex = findLastIndex(allBidOptions, (i: number) => i === highestBid);

    return allBidOptions[minimumBidIndex + 1];
};
