import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCatalogAbsenteeBidders } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveCatalogBidders, getLiveCatalogPaused } from '@/redux/modules/console';
import { getLiveItemStatusOpen } from '@/redux/modules/liveItemStatus';
import { shouldShowLive } from '@/utils/live';
import { useAppSelector } from '@/redux/hooks';
import ConsoleSocialBadge from '@/components/ConsoleSocial/ConsoleSocialBadge';
import InfoBadge from '@liveauctioneers/caterwaul-components/lib/Badges/InfoBadge';
import styled, { css } from 'styled-components';

export type ItemCardBadgeProps = {
    allowBidding?: boolean;
    className?: string;
    itemId: number;
    myItemsSavedItemCard?: boolean;
    pushSoldBadgeToLeftCorner?: boolean;
    savedItemsCard?: boolean;
};
const ItemCardBadge = ({
    allowBidding,
    className,
    itemId,
    myItemsSavedItemCard,
    pushSoldBadgeToLeftCorner,
    savedItemsCard,
}: ItemCardBadgeProps) => {
    const { catalogId, catalogStatus, isCatalogOnly, isLocked, isPassed, isSold, promoted, saleStartTs } =
        useAppSelector((state) => getItemSummary(state, itemId));

    const isLotOpen = useAppSelector((state) => getLiveItemStatusOpen(state, itemId));
    const isUnsold = isLocked && !isPassed && !isSold && !isLotOpen;
    const bidders = useAppSelector((state) => getLiveCatalogBidders(state, catalogId));
    const absenteeBidders = useAppSelector((state) => getCatalogAbsenteeBidders(state, catalogId));
    const paused = useAppSelector((state) => getLiveCatalogPaused(state, catalogId));
    const showLive = shouldShowLive(catalogStatus, isCatalogOnly, saleStartTs);
    const showSocial = allowBidding && showLive && !isSold && !isPassed;
    const viewers = bidders + absenteeBidders || 1;

    return (
        <>
            {promoted && !isSold && (
                <StyledFeaturedBadge
                    $myItemsSavedItemCard={myItemsSavedItemCard}
                    className={className}
                    color="lightGrey"
                >
                    <FormattedMessage id="featured" />
                </StyledFeaturedBadge>
            )}
            {isSold && !isPassed && (
                <StyledSoldBadge
                    $pushSoldBadgeToLeftCorner={pushSoldBadgeToLeftCorner}
                    className={className}
                    color="darkGrey"
                >
                    <FormattedMessage id="similarSoldItemCarousel.badge" />
                </StyledSoldBadge>
            )}
            {showSocial && !isSold && !isUnsold && (
                <StyledConsoleSocialBadge
                    $myItemsSavedItemCard={myItemsSavedItemCard}
                    $savedItemsCard={savedItemsCard}
                    className={className}
                    myItemsSavedItemsCard={myItemsSavedItemCard}
                    pause={paused}
                    viewers={viewers}
                />
            )}
        </>
    );
};

export default ItemCardBadge;

const StyledSoldBadge = styled(InfoBadge)<{
    $pushSoldBadgeToLeftCorner?: boolean;
}>`
    position: absolute;
    left: 8px;
    top: 8px;

    ${({ $pushSoldBadgeToLeftCorner, theme }) =>
        $pushSoldBadgeToLeftCorner &&
        css`
            @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                top: 4px;
                left: 4px;
            }
        `}
`;

export const StyledFeaturedBadge = styled(InfoBadge)<{
    $myItemsSavedItemCard?: boolean;
}>`
    position: absolute;
    top: 10px;
    left: 10px;
    ${({ $myItemsSavedItemCard, theme }) =>
        $myItemsSavedItemCard &&
        css`
            @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                top: 6px;
                left: 6px;
            }

            @media (max-width: ${theme.breakpoints.mobileWidth}) {
                top: 4px;
                left: 4px;
            }
        `}
`;

const StyledConsoleSocialBadge = styled(ConsoleSocialBadge)<{
    $myItemsSavedItemCard?: boolean;
    $savedItemsCard?: boolean;
}>`
    position: absolute;
    margin: 10px;
    top: 0;
    ${({ $savedItemsCard, theme }) =>
        $savedItemsCard &&
        css`
            margin: 8px 0 0 8px;
            @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                margin: 12px 0 0 8px;
            }
        `}

    ${({ $myItemsSavedItemCard, theme }) =>
        $myItemsSavedItemCard &&
        css`
            margin: 8px 0 0 8px;
            @media (max-width: ${theme.breakpoints.tabletNarrowWidth}) {
                margin: 0 0 0 4px;
                && {
                    padding: 0 6px 0 0;
                }
            }
        `}
`;
