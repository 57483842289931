import { BuyNowStatus } from '@/types/item/ItemSummary';
import { Caption, H5 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getItemModelUrl, getItemReserveMet, getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveItemStatusOpen } from '@/redux/modules/liveItemStatus';
import { isSearchPage } from '@/utils/urls';
import { SubtleMonochromeLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import FormattedCurrency from '@/components/Formatted/FormattedCurrency';
import ItemPriceGatewayContainer from '@/components/ItemPriceGateway/ItemPriceGatewayContainer';
import noop from '@/utils/noop';
import React from 'react';
import styled, { css } from 'styled-components';

export type ItemCardPriceRowProps = {
    className?: string;
    isHovered?: boolean;
    itemId: number;
    onMouseEnter?: React.MouseEventHandler<HTMLSpanElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>;
    onNavigate?: () => void;
    onUserLoggedIn?: () => void;
    showBidCount?: boolean;
};

export default function ItemCardPriceRow({
    className,
    isHovered,
    itemId,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onNavigate,
    showBidCount,
}: ItemCardPriceRowProps) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isLotOpen = useAppSelector((state) => getLiveItemStatusOpen(state, itemId));
    const isReserveMet = useAppSelector((state) => getItemReserveMet(state, itemId));
    const isOnSearchPage = isSearchPage(pathname);

    const {
        bidCount,
        buyNowPrice,
        buyNowStatus,
        catalogStatus,
        currency = 'USD',
        isAvailable,
        isCatalogOnly,
        isLocked,
        isPassed,
        isPaused,
        isSold,
        leadingBid,
        salePrice,
        startPrice,
    } = useAppSelector((state) => getItemSummary(state, itemId));

    const isDone = catalogStatus === 'done';
    const buyNowAvailable = buyNowStatus === BuyNowStatus.Available && isDone;
    const isUnsold = isLocked && !isPassed && !isSold && !isLotOpen;
    const currentPrice = leadingBid || salePrice || startPrice || 0;
    const itemUrl = useAppSelector((state) => getItemModelUrl(state, itemId));

    const spanProps: React.HTMLAttributes<HTMLSpanElement> = {
        onMouseEnter,
        onMouseLeave,
    };

    let returnComponent: React.ReactNode = null;
    const itemPriceGatewayContainerProps: React.ComponentProps<typeof ItemPriceGatewayContainer> = {
        hideIcon: true,
        itemId,
        onMouseEnter,
        onMouseLeave,
        runFunction: () => {
            onNavigate?.();
            navigate({ pathname: itemUrl });
        },
    };

    if (!isDone && isPaused) {
        returnComponent = (
            <ItemPriceGatewayContainer {...itemPriceGatewayContainerProps}>
                <span {...spanProps}>
                    <StyledPriceLinkText $isHovered={false}>
                        <FormattedMessage id="lotWithdrawn" />
                    </StyledPriceLinkText>
                </span>
            </ItemPriceGatewayContainer>
        );
    } else if (isLotOpen || buyNowAvailable || (isAvailable && !isUnsold && !isPaused && !isCatalogOnly)) {
        returnComponent = (
            <span {...spanProps}>
                <SubtleMonochromeLink
                    href={itemUrl}
                    onClick={() => onNavigate?.()}
                >
                    <StyledPriceLinkText $isHovered={isHovered}>
                        <FormattedCurrency
                            convert
                            noFraction
                            sourceCurrency={currency}
                            // current price unless buy now available PLUS is not lot open (preserves old order of operations)
                            value={buyNowAvailable && !isLotOpen ? buyNowPrice : currentPrice}
                        />
                        {showBidCount && (
                            <StyledCaption>
                                <FormattedMessage
                                    id="itemBidding.bid"
                                    values={{ bidCount }}
                                />
                            </StyledCaption>
                        )}
                    </StyledPriceLinkText>
                </SubtleMonochromeLink>
            </span>
        );
    } else if (isSold && isReserveMet) {
        returnComponent = (
            <ItemPriceGatewayContainer {...itemPriceGatewayContainerProps}>
                <span {...spanProps}>
                    <StyledSoldPriceText $isHovered={isHovered}>
                        {Boolean(salePrice) ? (
                            <FormattedMessage
                                id="sold_for"
                                values={{
                                    amount: (
                                        <FormattedCurrency
                                            key="sold-for-amount"
                                            noFraction
                                            sourceCurrency={currency}
                                            value={salePrice}
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage id="contact_seller_for_price" />
                        )}
                    </StyledSoldPriceText>
                </span>
            </ItemPriceGatewayContainer>
        );
    } else if (isSold && !isReserveMet) {
        returnComponent = (
            <ItemPriceGatewayContainer
                {...itemPriceGatewayContainerProps}
                runFunction={undefined}
            >
                <span {...spanProps}>
                    <SubtleMonochromeLink
                        href={itemUrl}
                        onClick={() => onNavigate?.()}
                    >
                        <StyledPriceLinkText $isHovered={isHovered}>
                            {(isCatalogOnly || isOnSearchPage) && <FormattedMessage id="lot_closed" />}
                        </StyledPriceLinkText>
                    </SubtleMonochromeLink>
                </span>
            </ItemPriceGatewayContainer>
        );
    } else if (isCatalogOnly && !isPassed) {
        returnComponent = (
            <span {...spanProps}>
                <SubtleMonochromeLink
                    href={itemUrl}
                    onClick={() => onNavigate?.()}
                >
                    <StyledPriceLinkText $isHovered={isHovered}>
                        <FormattedMessage id="registration.preview_only" />
                    </StyledPriceLinkText>
                </SubtleMonochromeLink>
            </span>
        );
    } else if (isUnsold) {
        returnComponent = (
            <ItemPriceGatewayContainer {...itemPriceGatewayContainerProps}>
                <span {...spanProps}>
                    <StyledPriceLinkText $isHovered={isHovered}>
                        <FormattedMessage id="lot_unsold_pending" />
                    </StyledPriceLinkText>
                </span>
            </ItemPriceGatewayContainer>
        );
    } else if (isPassed) {
        returnComponent = (
            <ItemPriceGatewayContainer {...itemPriceGatewayContainerProps}>
                <span {...spanProps}>
                    <StyledPriceLinkText $isHovered={isHovered}>
                        {isCatalogOnly ? (
                            <FormattedMessage id="lot_closed" />
                        ) : (
                            <FormattedMessage id="itemBidding.passedText" />
                        )}
                    </StyledPriceLinkText>
                </span>
            </ItemPriceGatewayContainer>
        );
    }

    return <section className={className}>{returnComponent}</section>;
}

const StyledCaption = styled(Caption)`
    margin-left: 4px;
    height: 100%;
`;

const StyledPriceLinkText = styled(H5)<{ $isHovered?: boolean }>`
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    align-items: flex-start;

    ${({ $isHovered }) =>
        $isHovered &&
        css`
            & span {
                color: ${({ theme }) => theme.colors.blue200} !important;
                text-decoration: underline !important;
                cursor: pointer !important;
            }
        `}
`;

const StyledSoldPriceText = styled(StyledPriceLinkText)`
    & > span {
        margin-left: 4px;
    }
`;
